@import url('https://fonts.googleapis.com/css?family=Nunito:400,400i,700|Spectral+SC&subset=latin-ext');
* {
  margin: 0;
}
html {
  height: 100%;
}
body {
  font-family: 'Nunito';
  display: flex;
  min-height: 100%;
}
body nav {
  flex-basis: 10em;
  flex-grow: 0;
  background: #541102;
  color: #CCC;
  font-weight: bold;
  position: relative;
  z-index: 30;
}
body nav div {
  width: 10em;
}
body nav div p {
  border-bottom: solid 1px gray;
  text-align: center;
  padding: 1.5em 0;
}
body nav div p span {
  display: block;
  font-size: 4em;
  margin-bottom: 0.1em;
}
body nav div p span img {
  width: 1em;
}
body nav div ul {
  visibility: hidden;
  position: absolute;
  background: #AAA;
  color: #222;
  left: 10em;
  width: 14em;
  margin-top: 11.172em;
  top: 0;
  bottom: 0;
  height: auto;
  padding: 0;
  list-style-type: none;
  z-index: 10;
}
@media (max-device-width: 800px) {
  body nav div ul {
    border-top: 1px solid black;
  }
}
body nav div ul li a {
  display: block;
  padding: 1em 0.8em;
  color: #222;
  text-decoration: none;
}
body nav div.logo {
  line-height: 1;
}
body nav div.logo p {
  height: 8.172em;
}
body nav div.logo p a {
  outline: 0;
}
body nav div.logo p img {
  width: 80%;
  margin-right: 0.1em;
}
body nav div:last-child p {
  border-bottom: 0;
}
body nav:hover div {
  background: none !important;
}
body nav:hover div:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}
body nav:hover div:hover ul {
  visibility: visible;
  z-index: 50;
}
@media (min-device-width: 800px) {
  body nav.society div.society {
    background: rgba(0, 0, 0, 0.5);
  }
  body nav.society div.society ul {
    visibility: visible;
  }
  body nav.sport div.sport {
    background: rgba(0, 0, 0, 0.5);
  }
  body nav.sport div.sport ul {
    visibility: visible;
  }
  body nav.press div.press {
    background: rgba(0, 0, 0, 0.5);
  }
  body nav.press div.press ul {
    visibility: visible;
  }
}
body nav footer {
  width: 10em;
  padding: 1em 0;
}
body nav footer .popup-credits {
  display: block;
  position: absolute;
  bottom: 0.5em;
  left: 0.5em;
  background: rgba(200, 200, 200, 0.8);
  color: black;
  visibility: hidden;
  border-radius: 0.5em;
  border: solid 1px #222;
  padding: 0.5em;
  width: 10em;
  font-style: italic;
}
body nav footer p {
  font-weight: normal;
  font-size: 0.8em;
  margin: 0.5em 0.5em;
}
body nav footer p:hover .popup-credits {
  visibility: visible;
}
body main {
  background: lightgray;
  flex-grow: 1;
  z-index: 20;
  flex-basis: 10em;
}
@media (min-device-width: 800px) {
  body main {
    margin-left: 14em;
  }
}
body main .top-header {
  height: 11.172em;
  text-align: center;
  background-image: url('../img/woods.jpg');
  background-size: cover;
  box-sizing: border-box;
  margin-left: -14em;
  position: relative;
}
body main .top-header div {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 14em;
  background: #AAA;
  color: #222;
  text-align: left;
  padding: 0.5em 1em 0.4em 1em;
  font-family: 'Spectral SC';
}
body main .top-header div p {
  font-size: 1.5em;
  line-height: 1.2;
}
body main section {
  margin: 1em;
}
body main section p,
body main section ul {
  margin-bottom: 0.5em;
}
body main.home {
  position: relative;
  margin: 0;
}
body main.home .top-header {
  height: 100%;
  margin: 0;
}
body main.home section {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  background: linear-gradient(to bottom, rgba(1, 1, 1, 0), #010101 100%);
}
body main.home section p {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  margin: 0;
  color: #ccc;
  font-size: 3.5em;
  font-family: 'Spectral SC';
}
body main.home section p span {
  font-size: 0.6em;
}
